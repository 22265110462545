<template>
  <div>
    <b-row class="mt-1">

      <!-- <b-col md="12">
        <div class="text-center pb-1">
          <img :src="items.location.site.logo_thumbnail" class="img-fluid rounded" alt="" height="50%" width="auto">
        </div>
      </b-col> -->

      <b-col md="12">
        <!-- <h2 class="text-center">
          {{dateTime}}
        </h2>
        <h1 class="text-center">
          <strong> {{'Rate our location ' + items.location.name}} </strong>
        </h1> -->
        <h1 class="text-center">
          {{items.location.site.site_name.toUpperCase()}}
        </h1>
        <h3 class="text-center">
          {{location1}}
        </h3>
        <h3 class="text-center">
          {{location2}}
        </h3>

      </b-col>
    </b-row>
    <b-row class="mt-2 mb-1">
      <b-col md="12">
        <h4 class="text-center">
            Please Rate our Location
        </h4>
      </b-col>
    </b-row>

    <b-row class="mt-3">
          
      <b-col md="6" cols="6" sm="6">
        <center>
            <img :src="happy" fluid style="max-width: 100%; height: auto" @click="feedbackClicked('happy')"/>
        </center>
      </b-col>

      <b-col md="6" cols="6" sm="6">
        <center>
            <img :src="unhappy" fluid style="max-width: 100%; height: auto" @click="feedbackClicked('unhappy')"/>
        </center>
      </b-col>

    </b-row>

  </div>
</template>

<script>
/* eslint-disable global-require */
import {
  BRow, BCol, BLink, BFormGroup, BFormInput, BInputGroupAppend, BInputGroup, BFormCheckbox, BCardText, BCardTitle, BImg, BForm, BButton, BAlert, VBTooltip,BCard,BMedia,BAvatar
} from 'bootstrap-vue'

import store from '@/store/index'
import { POST_API } from "../../store/actions.type"
import moment from "moment";
export default {
  props:['happy','unhappy','items'],
  components: {
    BRow,
    BCol,
    BLink,
    BFormGroup,
    BFormInput,
    BInputGroupAppend,
    BInputGroup,
    BFormCheckbox,
    BCardText,
    BCardTitle,
    BImg,
    BForm,
    BButton,
    BAlert,
    BCard,
    BMedia,
    BAvatar
  },
  data() {
    return {
      dateTime  : moment().tz('Asia/Singapore').format('DD MMM, YYYY HH:mm'),
      location1 : '',
      location2 : '',
    }
  },
  computed: {
  },
  methods: {
    feedbackClicked(category) {
      this.$emit('feedbackResponse',category);
    },
    getLocationName(location){
      if(location.includes("Male Toilet") || location.includes("Female Toilet") || location.includes("Handicap Toilet") || location.includes("Nurse Room") || location.includes("Baby Room")){             
        if(location.includes("Male Toilet")){
          var loc_name = location.split("Male Toilet");
          var suffix = "Male Toilet";
        } else if (location.includes("Female Toilet")){
          var loc_name = location.split("Female Toilet");
          var suffix = "Female Toilet";
        } else if (location.includes("Handicap Toilet")){
          var loc_name = location.split("Handicap Toilet");
          var suffix = "Handicap Toilet";
        } else if (location.includes("Nurse Room")){
          var loc_name = location.split("Nurse Room");
          var suffix = "Nurse Room";
        } else if (location.includes("Baby Room")){
          var loc_name = location.split("Baby Room");
          var suffix = "Baby Room";
        }

        this.location1 = loc_name[0].trim().toUpperCase();
        this.location2 = (suffix+''+loc_name[1]).trim().toUpperCase();       
      } else {
        this.location1 = location;
      }
    }
  },
  mounted(){
    this.getLocationName(this.items.location.name)
  }
}
</script>

<style>

  .border-danger {
      border: 5px solid #ea5455 !important;
  }

  .border-secondary {
      border: 5px solid rgb(221, 221, 221) !important;
  }

  .card-body {
    min-height: 228px !important;
  }

</style>
