<template>
  <div>
    <b-row class="mt-1">

      <!-- <b-col md="12">
        <div class="text-center pb-1">
          <img :src="items.location.site.logo_thumbnail" class="img-fluid rounded" alt="" height="50%" width="auto">
        </div>
      </b-col> -->

      <b-col md="12">
        <h1 class="text-center">
          {{items.location.site.site_name.toUpperCase()}}
        </h1>
        <h3 class="text-center">
          {{location1}}
        </h3>
        <h3 class="text-center">
          {{location2}}
        </h3>
      </b-col>
    </b-row>

    <!-- <b-row class="mt-2 mb-1">
      <b-col md="12">
        <h4 class="text-center">
            Which facilities do you want to feedback on?
        </h4>
      </b-col>
    </b-row> -->

    <b-form @submit="formSubmit">

      <b-row class="mt-3">
          
        <b-col md="3" cols="6" sm="6"  v-for="(category,index) in items.categories" :key="index">
            <b-card
              bg-variant="transparent"
              :border-variant="category.selected == false ? 'secondary' : 'danger'"
              text-variant="black"
              @click="updateSelect(category)"
              class="overflow-hidden"
            >
              
                
              <center>
                <img :src="category.image" variant="light-primary" style="width:100%"/>
              </center>
                    
              
              
              <h5 class="text-center mt-1 categoryH5">
                {{category.name}}
              </h5>
            
            </b-card>
        
        </b-col>

      </b-row>

      <b-row class="mt-1 mb-3">
        <b-col md="6" cols="6">
          <div>
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              block
              variant="secondary"
              @click="goBack('back')"
            >
              Back
            </b-button>
          </div>
        </b-col>
        <b-col md="6" cols="6">
          <div>
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              block
              variant="warning"
              @click="formSubmit"
              :disabled="disabled"
            >
              Submit
            </b-button>
          </div>
        </b-col>
      </b-row>
    </b-form>

    
    <!-- @ok="actualFormSubmit" -->
    <!-- ok-title="Submit"
    cancel-title="Close" -->
    <b-modal
        id="feedback-modal" centered ref="feedback-modal" cancel-variant="outline-secondary"
        ok-variant="outline-warning"
        title="Feedback Items"
        no-close-on-backdrop
        @show="resetForm"
        @hidden="resetForm"
        hide-footer
        size="md"
        class="mb-0"
    >
      <div class="mb-1"> <h4 class="">{{getItemName()}}</h4> </div>
      <!-- <hr> -->
      <b-row>
        <b-col cols="12" class="mt-0" v-if="items.location.site.no_photo_reporting == 'no'" style="min-height:75px;">
          <!-- image block -->
          <!-- <b-form-group label="Upload Image : " class=""> -->
            <label class="image-label m-0 p-0 text-white" for="file-upload">Upload Image : 
              <img style="height: 32px; width: auto;" :src="doc_icon" alt="">
            </label>

            <div class="image-block p-0 mt-2">
                <input type="file" accept="image/*" id="file-upload" multiple @change="handleFileUpload">
                <div class="image-container">
                    <div v-for="(image, index) in images" :key="index" class="px-1 pb-1">
                            <img :src="image.image" alt="Preview" class="profile-pic rounded mb-1" > 
                        <div class="text-center">
                            <button class="btn btn-sm btn-danger rounded" @click="removeImage(index)">
                              <feather-icon icon="XIcon" />
                            </button>
                        </div>
                    </div>
                </div>
            </div>
          <!-- </b-form-group> -->

        </b-col>

        <!-- <b-col cols="12" class="mt-0" v-if="items.location.site.no_photo_reporting == 'yes'"> removed check for comment. Check applicable only for image --> 
        <b-col cols="12" class="mt-0">
          <b-form-group label="Comment :" class="">
            <b-form-textarea placeholder="Upto 500 characters" rows="4" v-model="comment" />
          </b-form-group>
        </b-col>

      </b-row>

      <b-row class="mb-1">
        <b-col cols="12">
          <div class="text-center">
            <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="secondary" @click="$refs['feedback-modal'].hide(); resetForm">Cancel</b-button>
            <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="warning" class="ml-1" @click="actualFormSubmit" :disabled="btn_status">Submit</b-button>
          </div>
        </b-col>
      </b-row>

    </b-modal>
    
  </div>
</template>

<script>
/* eslint-disable global-require */
import {
  BRow, BCol, BLink, BFormGroup, BFormInput, BInputGroupAppend, BInputGroup, BFormCheckbox, BCardText, BCardTitle, BImg, BForm, BButton, BAlert, VBTooltip,BCard,BMedia,BAvatar, BModal, BFormTextarea
} from 'bootstrap-vue'

import store from '@/store/index'
import { POST_API } from "../../store/actions.type"
import Ripple from 'vue-ripple-directive'

export default {
  props:['items'],
  components: {
    BRow,
    BCol,
    BLink,
    BFormGroup,
    BFormInput,
    BInputGroupAppend,
    BInputGroup,
    BFormCheckbox,
    BCardText,
    BCardTitle,
    BImg,
    BForm,
    BButton,
    BAlert,
    BCard,
    BMedia,
    BAvatar,
    BModal,
    BFormTextarea
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      location1     : '',
      location2     : '',
      disabled      : false,
      image_block   : false,
      comment_block : false,
      images        : [],
      comment       : '',
      doc_icon      : require('@/assets/images/upload.png'),
      btn_status    : false,
      show_additional_information : 'no',
    }
  },
  computed: {
  },
  methods: {
    handleFileUpload(event) {
      const files = event.target.files;
      const totalImages = this.images.length + files.length;
      
      if (totalImages > 5) {
        this.customAlert2('Maximum 5 images allowed.', '', 'error', 'center', false);
        return;
      }
      
      for (let i = 0; i < files.length; i++) {
        const reader = new FileReader();

        if (!files[i].type.startsWith('image/')) {
          this.customAlert2('Only images are allowed.', '', 'error', 'center', false);
          return;
        }
        
        reader.onload = (e) => {
          this.images.push({
            // file: files[i],
            // previewUrl: e.target.result
            default : 'no',
            image : e.target.result,
            name : 'feedback_image.png',
            type : 'supervisor'
          });
        };
        reader.readAsDataURL(files[i]);
      }
    },

    removeImage(index) {
      this.images.splice(index, 1);
    },
    
    resetForm(){
      this.image_block = false;
      this.comment_block = false;
      this.images = [];
      this.comment = '';
      this.images = []; 
      this.btn_status = false;
    },

    getItemName(){
      var selectedNames = this.items.categories.filter(item => item.selected).map(item => item.name).join(', ');
      return selectedNames;
    },

    goBack(category){
      this.$emit('feedbackResponse',category);
    },

    updateSelect(category){
      if (category.selected == false) {

        var selected = this.items.categories.reduce((acc,curr) => {
          curr.selected == true ? acc++ : acc;
          return acc
        },0);
        
        if (selected < 3) {
          return category.selected = true;
        }else{
          Swal.fire({
            position : 'center',
            icon     : 'warning',
            title    : 'Maximum 3 facilities can be selected',
            timer    : 1500,
            showConfirmButton : false
            
          });
        }

      
      }else{
        return category.selected = false;
      }
    },

    formSubmit(e){

      e.preventDefault();

      var allSelectedFalse = this.items.categories.every(item => !item.selected);

      // If all selected keys are false, show an alert
      if (allSelectedFalse) {
          this.customAlert2('Please Select Facility', '', 'error', 'center', false);
      } else {
        if(this.show_additional_information == 'yes'){
          this.$refs['feedback-modal'].show();
        } else {
          this.actualFormSubmit2();
        }
      }
      
      
    },

    actualFormSubmit2(){
        this.disabled=true;
        return this.$store.dispatch(POST_API, {
            data:{
                items:this.items,
                from:'web'
            },
              api: '/api/add-feedback'
            // api: '/api/add-feedback-request-qr'   // will be enabled in future 
        })
        .then(() => {
            if (this.$store.getters.containsErrors) {
                this.error_message = this.$store.getters.getErrors;
                this.errorAlert();
            } else {
                this.showDismissibleAlert = false;
                var data  = this.$store.getters.getResults.data;
              
                this.items.categories.map((item)=> item.selected = false);
                Swal.fire({
                  position : 'center',
                  icon     : 'success',
                  title    : this.$store.getters.getResults.message,
                  showConfirmButton: false,
                  allowOutsideClick: false,
                  allowEscapeKey: false
                });
                setTimeout(function(){
                  window.close();
                },3000);
                return this.items;
            }
        });
    },

    actualFormSubmit(){
      // this.disabled=true;
      // var feedback_mode = this.image_block == false && this.comment_block == true ? 'image' : 'comment';
      this.btn_status = true;
      return this.$store.dispatch(POST_API, {
              data:{
                  items:this.items,
                  // feedback_mode : feedback_mode,
                  comment : this.comment,
                  images : this.images.length > 0 ? this.images : null,
                  from : 'web'
              },
              api: '/api/add-feedback-web' 
              //  api: '/api/add-feedback' // commented for new api which have data params like comment and images
              // api: '/api/add-feedback-request-qr'   // will be enabled in future 
          })
          .then(() => {
              if (this.$store.getters.containsErrors) {
                  this.error_message = this.$store.getters.getErrors;
                  this.btn_status = false;
                  this.errorAlert();
              } else {
                  this.showDismissibleAlert = false;
                  var data  = this.$store.getters.getResults.data;
                  
                  /*this.successAlert();*/
                  this.items.categories.map((item)=> item.selected = false);
                  Swal.fire({
                    position : 'center',
                    icon     : 'success',
                    title    : this.$store.getters.getResults.message,
                    /*timer    : 1500,
                    showConfirmButton : false*/
                    showConfirmButton: false,
                    allowOutsideClick: false,
                    allowEscapeKey: false
                  });
                  //window.close()
                  setTimeout(function(){
                   window.close();
                  },3000);
                  return this.items;
              }
          });
        
      
    },

    getLocationName(location){
      if(location.includes("Male Toilet") || location.includes("Female Toilet") || location.includes("Handicap Toilet") || location.includes("Nurse Room") || location.includes("Baby Room")){             
        if(location.includes("Male Toilet")){
          var loc_name = location.split("Male Toilet");
          var suffix = "Male Toilet";
        } else if (location.includes("Female Toilet")){
          var loc_name = location.split("Female Toilet");
          var suffix = "Female Toilet";
        } else if (location.includes("Handicap Toilet")){
          var loc_name = location.split("Handicap Toilet");
          var suffix = "Handicap Toilet";
        } else if (location.includes("Nurse Room")){
          var loc_name = location.split("Nurse Room");
          var suffix = "Nurse Room";
        } else if (location.includes("Baby Room")){
          var loc_name = location.split("Baby Room");
          var suffix = "Baby Room";
        }

        this.location1 = loc_name[0].trim().toUpperCase();
        this.location2 = (suffix+''+loc_name[1]).trim().toUpperCase();       
      } else {
        this.location1 = location;
      }
    }
  },
  mounted(){
    this.show_additional_information = this.items.location.site.additional_information;

    this.items.categories.map((item)=> item.selected = false);
    this.getLocationName(this.items.location.name)
  }
}
</script>

<style>

  .border-danger {
      border: 5px solid #ea5455 !important;
  }

  .border-secondary {
      border: 5px solid rgb(221, 221, 221) !important;
  }

  .card-body {
    min-height: 228px !important;
  }

    /* Center the content vertically and horizontally */
    .image-block {
      display: flex;
      flex-direction: column;
      align-items: center;
      /* justify-content: center; */
    }

    /* Style for the file input */
    input[type="file"] {
      display: none;
    }

    /* Style for the file input label */
    .image-label {
      margin-bottom: 0;
      color: #ffffff;
      /* background-color: #00cfe8; */
      font-size: 15px;
      cursor: pointer;
      border-radius: 4px;
      transition: background-color 0.3s ease;
    }

    .image-container {
      display: flex;
      flex-wrap: wrap;
      /* margin-top: 20px; */
    }

    .profile-pic {
      width: 64px;
      height: 64px;
      object-fit: cover;
      cursor: pointer;
    }

  </style>
