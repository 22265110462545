<template>
  <!-- Error page-->
  <div class="misc-wrapper" v-if="items.location != null && items.location.qr_status == 'active'">
    <div class="misc-inner p-2 p-sm-3">

      <Happy :items ="items" :happy="happy" :unhappy="unhappy" v-if="curr_page == 'happy'" class="mb-2" @feedbackResponse= "feedbackResponse"/>
      <UnHappy :items = "items" v-if="curr_page == 'unhappy'" @feedbackResponse= "feedbackResponse"/>

      <div v-if="desclaimer != ''" class="pb-1 disclaimer">
        {{desclaimer}}
      </div>
    </div>

    
  </div>
  <div class="misc-wrapper" v-else>

    <div class="misc-inner p-2 p-sm-3" style="position: absolute;top: 40%;">

      <h2 class="text-center">QR Scan Facility has been disabled for this location. Please Contact EL Taskforce Support Team.</h2>
    </div>

  </div>
<!-- / Error page-->
</template>

<script>
/* eslint-disable global-require */
import {
  BRow, BCol, BLink, BFormGroup, BFormInput, BInputGroupAppend, BInputGroup, BFormCheckbox, BCardText, BCardTitle, BImg, BForm, BButton, BAlert, VBTooltip,BCard,BMedia,BAvatar
} from 'bootstrap-vue'

import store from '@/store/index'
import { GET_API, POST_API } from "../store/actions.type"
import Happy from '../views/feedbackComponent/Happy';
import UnHappy from '../views/feedbackComponent/UnHappy';

export default {
  components: {
    BRow,
    BCol,
    BLink,
    BFormGroup,
    BFormInput,
    BInputGroupAppend,
    BInputGroup,
    BFormCheckbox,
    BCardText,
    BCardTitle,
    BImg,
    BForm,
    BButton,
    BAlert,
    BCard,
    BMedia,
    BAvatar,
    Happy,
    UnHappy
  },
  data() {
    return {
      items :{
        categories : [],
        location:null
      },
      happy:null,
      unhappy:null,
      curr_page : "happy",
      desclaimer:''
    }
  },
  computed: {
  },
  methods: {
    getData(){
      return this.$store.dispatch(POST_API, {
         data:{
           
           location_id:this.$route.params.id,
         },
         api: '/api/qr-category-by-locations'
      })
      .then(() => {
          if (this.$store.getters.containsErrors) {
              this.error_message = this.$store.getters.getErrors;
          } else {
              var data = this.$store.getters.getResults.data;
              this.items.categories = data.categories;
              this.items.location = data.location;
              this.happy = data.feedbackPanelData.happy;
              this.unhappy = data.feedbackPanelData.unhappy;
              this.desclaimer = data.feedbackPanelData.desclaimer;
              return this.items;
          }
      });
    },
    feedbackResponse(e){
      if (e == 'unhappy') {
        this.curr_page = 'unhappy';
      }else if(e == 'happy'){
        this.curr_page = 'happy';
        this.happyFeedback();
      }else{
        this.curr_page = 'happy';
      }
    },
    happyFeedback(){
      return this.$store.dispatch(POST_API, {
          data:{
            feedback_from:'web'
          },
          api: '/api/happy-feedback/'+this.items.location._id
      })
      .then(() => {
          if (this.$store.getters.containsErrors) {
              this.error_message = this.$store.getters.getErrors;
              this.errorAlert();
          } else {
              this.showDismissibleAlert = false;

              //this.successAlert();
              Swal.fire({
                position : 'center',
                icon     : 'success',
                title    : this.$store.getters.getResults.message,
                /*timer    : 1500,
                showConfirmButton : false*/
                showConfirmButton: false,
                allowOutsideClick: false,
                allowEscapeKey: false
              });
              //window.close()
              setTimeout(function(){
               window.close();
              },3000);
              return this.items;
          }
      });
    }
  },
  mounted(){
    this.getData();
  }
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-misc.scss';
</style>
<style>

  .card-body {
    min-height: 228px !important;
  }

  .misc-wrapper {
    align-items: normal !important;
  }

  .disclaimer {
    position: absolute;
    bottom: 0;
    text-align: center;
    padding-left: 10px;
    padding-right: 10px;
    left:0;
    right:0;
  }

</style>
